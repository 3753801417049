import { AccountSource } from "__generated__/GraphQLTypes";
import { authManager } from "app/auth/utils";
import {
  BarChartIcon,
  BuldingIcon,
  ClockIcon,
  DriverIcon,
  RoleIcon,
  SplitIcon,
  UserIcon,
  UsersIcon,
  WebHookIcon
} from "app/icons";
import { AppRoutes } from "app/routing";
import { IMenuGroup } from "app/types";
import { useTranslation } from "react-i18next";
import { AppPlatform } from ".";
import ABILITIES from "./abilities";

const useMenuGroup = (): { MENU: IMenuGroup[] } => {
  const { t } = useTranslation();

  const token = authManager.readToken();

  const MENU: IMenuGroup[] = [
    {
      key: "cbc3903e-15b6-4e29-917d-3bce960947c1",
      label: "",
      abilities: [],
      menus: [
        {
          key: "b234d861-3712-4870-a3f2-b8be6f16050f",
          label: t("all.dashboard"),
          icon: BarChartIcon,
          url: AppRoutes.HOME,
          abilities: [],
          hide: true
        },
        {
          key: "d651ffc4-5540-441d-bdad-1a6a565ee2f4",
          label: t("all.campaign", { count: 2 }),
          icon: WebHookIcon,
          url: AppRoutes.CAMPAIGNS,
          abilities: []
        },
        {
          key: "283e984a-dbe1-4072-81eb-886bf41ff17e",
          label: t("all.transmission", { count: 2 }),
          icon: SplitIcon,
          url: AppRoutes.TRANSMISSIONS,
          abilities: [ABILITIES.transmission.can_view]
        }
      ]
    },
    {
      key: "a612d701-9cdd-4920-b071-9b870a9a9cd1",
      label: "",
      abilities: [],
      menus: [
        {
          key: "4479ac41-c3c0-4497-a071-5bd8a34646ca",
          label: t("all.agency", { count: 2 }),
          icon: BuldingIcon,
          url: AppRoutes.AGENCIES,
          abilities: [ABILITIES.agency.can_view]
        },
        {
          key: "44d0b8f5-ad82-4490-9aaf-1c97305cfa62",
          label: t("all.receiver", { count: 2 }),
          icon: UserIcon,
          url: AppRoutes.RECEIVERS,
          abilities: [ABILITIES.receiver.can_view]
        },
        {
          key: "1d01a015-31fb-4c33-836b-e7900f73b2c2",
          label: t("all.papser", { count: 2 }),
          icon: DriverIcon,
          url: AppRoutes.PAPSERS,
          abilities: [ABILITIES.papser.can_view]
        },
        {
          key: "1d01a015-31fb-4c33-836b-e7900f73ad20",
          label: t("all.history", { count: 2 }),
          icon: ClockIcon,
          url: AppRoutes.HISTORY,
          abilities: [ABILITIES.history.can_view],
          children: [
            {
              key: "1d01a015-31fb-4c33-836b-e7900f79g2h7",
              label: t("all.campaign", { count: 2 }),
              url: AppRoutes.CAMPAIGNS_HISTORY,
              abilities: []
            },
            {
              key: "1d01a015-31fb-4c33-836b-e7900f90b5h0",
              label: t("all.transmission", { count: 2 }),
              url: AppRoutes.TRANSMISSIONS_HISTORY,
              abilities: []
            }
          ]
        }
      ]
    },
    {
      key: "bde52be7-2a80-4338-b2a7-0e27dca45de9",
      label: "",
      abilities: [],
      hide:
        process.env.REACT_APP_PLATFORM === AppPlatform.Client ||
        token?.user?.account?.source === AccountSource.Discovery,
      menus: [
        {
          key: "7a516646-d86d-4949-93d1-6c93f5313077",
          label: t("all.organization", { count: 2 }),
          icon: BuldingIcon,
          url: AppRoutes.ORGANIZATIONS,
          abilities: [ABILITIES.organization.can_view]
        },
        {
          key: "29863575-e80a-46d8-a000-131e8c701473",
          label: t("all.user", { count: 2 }),
          icon: UsersIcon,
          url: AppRoutes.USERS,
          abilities: [ABILITIES.user.can_view]
        },
        {
          key: "48a11a56-b5e8-4d16-8a47-d98d199a091d",
          label: t("all.role", { count: 2 }),
          icon: RoleIcon,
          url: AppRoutes.ROLES,
          abilities: [ABILITIES.role.can_view]
        }
      ]
    }
  ];

  return { MENU };
};

export default useMenuGroup;
