import { gql } from "@apollo/client";

export const AUTH = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      user {
        _id
        firstname
        lastname
        phone
        email
        role {
          _id
          name
          permissions {
            _id
            name
            module {
              _id
              name
            }
          }
        }
        account {
          _id
          source
        }
        organizationId
      }
    }
  }
`;

export const AUTH_CLIENT = gql`
  mutation authDCClient($input: AuthInput!) {
    authDCClient(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const ACCOUNT_VERIFICATION = gql`
  mutation verifyAccount($input: AccountVerificationInput!) {
    verifyAccount(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const RESET_PASSWORD_REQUEST = gql`
  mutation resetPasswordRequest($input: ResetPasswordRequestInput!) {
    resetPasswordRequest(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
