import { gql } from "@apollo/client";

export const ALL_ORGANIZATIONS = gql`
  query organizations($first: Int, $after: String, $before: String, $last: Int) {
    organizations(first: $first, after: $after, before: $before, last: $last) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        countTotal
        countCurrent
        countBefore
        countAfter
      }
      nodes {
        _id
        name
        logo
        isGKS
        website
        phone
        email
        about
        isRestrictions
        contact {
          email
          phone
        }
        stats {
          totalAgencies
          totalReceivers
        }
      }
    }
  }
`;

export const ORGANIZATION_BY_ID = gql`
  query organization($id: ID!) {
    result: organization(id: $id) {
      _id
      name
      phone
      logo
      isGKS
      website
      email
      about
      isRestrictions
      contact {
        email
        phone
      }
      agencies {
        _id
        name
        address {
          region
          place_id
          address
        }
        email
        phone {
          fix
          mobile
        }
      }
      receivers {
        _id
        reference
        firstname
        lastname
        address {
          address
          location {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export const ALL_ORGANIZATIONS_FOR_SELECTIONS = gql`
  query Orgarnisations {
    organizations {
      nodes {
        _id
        name
        isGKS
        isRestrictions
      }
    }
  }
`;

export const GET_USER_ORGANIZATION = gql`
  query organization($id: ID!) {
    result: organization(id: $id) {
      _id
      name
      phone
      logo
      website
      email
      about
      isRestrictions
      isGKS
      contact {
        email
        phone
      }
    }
  }
`;
