import React, { useEffect, useState } from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useQuery } from "@apollo/client";
import { AccountSource, Permission } from "__generated__/GraphQLTypes";
import { AbitlityProvider } from "app/shared/@pbac";
import { DISCOVERY_USER_BY_ID, USER_BY_ID } from "graphql/users/queries";
import useToggle from "app/hooks/useToggle";
import Loading from "app/ui/loading";
import { authManager } from "./utils";

interface PrivateRouteProps extends RouteComponentProps {
  as: React.FC<RouteComponentProps>;
  path: string;
}

export function PrivateRoute(props: PrivateRouteProps): JSX.Element {
  const { as: Component, ...rest } = props;
  const token = authManager.readToken();

  const { data: userData, loading } = useQuery(USER_BY_ID, {
    variables: {
      id: token?.user?._id
    }
  });

  const { data: discoveryUserData, loading: discoveryUserDataLoading } = useQuery(DISCOVERY_USER_BY_ID, {
    variables: {
      id: token?.user?._id
    }
  });

  const [abilities, setAbilities] = useState(null);
  const [loadAbilities, setLoadAbilities] = useToggle();

  useEffect(() => {
    if (!loading && !discoveryUserDataLoading) {
      setLoadAbilities();
      if (userData || discoveryUserData) {
        setAbilities(
          token?.user?.account?.source === AccountSource.Discovery
            ? discoveryUserData?.discoveryUser?.role?.permissions?.map(
                (permission: Permission) => `${permission.name}:${permission.module.name}`
              )
            : userData?.user?.role?.permissions?.map(
                (permission: Permission) => `${permission.name}:${permission.module.name}`
              )
        );
      } else {
        setAbilities([]);
      }
    }
  }, [loading, discoveryUserDataLoading, userData, discoveryUserData]);

  if (!token) return <Redirect noThrow to="/auth/login" />;

  return (
    <AbitlityProvider abilities={abilities}>
      {loadAbilities ? (
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      ) : (
        <Loading />
      )}
    </AbitlityProvider>
  );
}
