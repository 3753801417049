import React, { Suspense } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { Skeleton } from "antd";
import { AuthLayout } from "app/ui/Layout";
import Activation from "./containers/Activation";
import Login from "./containers/Login";
import Reset from "./containers/Reset";
import Forgot from "./containers/Forgot";
import { authManager } from "./utils";

const Auth: React.FC<RouteComponentProps> = (): JSX.Element => {
  const token = authManager.readToken();

  if (token) return <Redirect noThrow to="/" />;

  return (
    <AuthLayout>
      <Suspense fallback={<Skeleton />}>
        <Router>
          <Login path="login" />
          <Forgot path="forgot" />
          <Reset path="reset/:token" />
          <Activation path="activation/:token" />
        </Router>
      </Suspense>
    </AuthLayout>
  );
};

export default Auth;
export * from "./PrivateRoute";
