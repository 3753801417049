import { gql } from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
};

export type Account = {
  __typename?: "Account";
  _id?: Maybe<Scalars["ID"]["output"]>;
  createdAt: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  isVerified?: Maybe<Scalars["Boolean"]["output"]>;
  resetToken?: Maybe<Scalars["String"]["output"]>;
  source: AccountSource;
  updatedAt: Scalars["String"]["output"];
  verificationToken?: Maybe<Scalars["String"]["output"]>;
  verifiedAt?: Maybe<Scalars["String"]["output"]>;
};

export enum AccountSource {
  Discovery = "Discovery",
  Partner = "Partner",
  Staff = "Staff"
}

export type AccountVerificationInput = {
  password: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type AddReceiversToTransmissionInput = {
  /** Receiver id list */
  receiverIds: Array<Scalars["ID"]["input"]>;
  /** Transmission id! */
  transmissionId: Scalars["ID"]["input"];
};

export type Address = {
  __typename?: "Address";
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Location>;
  place_id?: Maybe<Scalars["ID"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
};

export type AddressInput = {
  /** Additional info! */
  additional_address?: InputMaybe<Scalars["String"]["input"]>;
  /** address! */
  address?: InputMaybe<Scalars["String"]["input"]>;
  /** city! */
  city?: InputMaybe<Scalars["String"]["input"]>;
  /** Latitude & Longitude location! */
  location?: InputMaybe<LocationInput>;
  /** Google place id! */
  placeId: Scalars["String"]["input"];
  /** region! */
  region?: InputMaybe<Scalars["String"]["input"]>;
};

export type Agency = {
  __typename?: "Agency";
  _id?: Maybe<Scalars["ID"]["output"]>;
  /** Agency address(should be valid google address)! */
  address?: Maybe<Address>;
  campaigns: Array<Campaign>;
  createdAt: Scalars["String"]["output"];
  /** Agency email! */
  email?: Maybe<Scalars["String"]["output"]>;
  /** Agency name! */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The organization to which the agency belongs */
  organization?: Maybe<Organization>;
  /** Agency phone info! */
  phone?: Maybe<Phone>;
  updatedAt: Scalars["String"]["output"];
};

export type AgencyEdge = {
  __typename?: "AgencyEdge";
  cursor: Scalars["String"]["output"];
  node: Agency;
};

export type AgencyPage = {
  __typename?: "AgencyPage";
  edges?: Maybe<Array<AgencyEdge>>;
  nodes?: Maybe<Array<Agency>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AssignDriversToTransmissionInput = {
  /** Driver id list */
  driverIds: Array<Scalars["ID"]["input"]>;
  /** Driver type */
  driverType: Scalars["String"]["input"];
  /** Transmission id! */
  transmissionId: Scalars["ID"]["input"];
};

export type AuthInput = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type AuthResponse = {
  __typename?: "AuthResponse";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  expiresIn?: Maybe<Scalars["Float"]["output"]>;
  refreshExpiresIn?: Maybe<Scalars["Float"]["output"]>;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

export type AuthenticateCodeInput = {
  code: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
};

export type AuthenticateCodeResponse = {
  __typename?: "AuthenticateCodeResponse";
  jwtToken: Scalars["String"]["output"];
  user: UserDriver;
};

export type BaseQueryArgs = {
  exportFilter?: InputMaybe<ExportReceiverInput>;
  filter?: InputMaybe<BaseQueryFilter>;
  paging?: InputMaybe<Page2Args>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type BaseQueryEqualsFilter = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type BaseQueryFilter = {
  equals?: InputMaybe<Array<BaseQueryEqualsFilter>>;
  ranges?: InputMaybe<Array<BaseQueryRangesFilter>>;
};

export type BaseQueryRangesFilter = {
  key: Scalars["String"]["input"];
  value: BaseQueryRangesValue;
};

export type BaseQueryRangesValue = {
  max: Scalars["String"]["input"];
  min: Scalars["String"]["input"];
};

export type Campaign = {
  __typename?: "Campaign";
  _id?: Maybe<Scalars["ID"]["output"]>;
  /** The agency to which the campaign belongs! */
  agency?: Maybe<Agency>;
  /** Campaign closed date! */
  closedDate?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["String"]["output"];
  /** Campaign ending date! */
  endDate?: Maybe<Scalars["String"]["output"]>;
  /** Is Campaign closed! */
  isClose?: Maybe<Scalars["Boolean"]["output"]>;
  /** Campaign name! */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The Organization to which the campaign belongs! */
  organization?: Maybe<Organization>;
  /** Campaign start date! */
  startDate?: Maybe<Scalars["String"]["output"]>;
  stats: CampaignStats;
  /** All transmissions belonging to the campaign! */
  transmissions?: Maybe<Array<Transmission>>;
  updatedAt: Scalars["String"]["output"];
};

export type CampaignEdge = {
  __typename?: "CampaignEdge";
  cursor: Scalars["String"]["output"];
  node: Campaign;
};

export type CampaignPage = {
  __typename?: "CampaignPage";
  edges?: Maybe<Array<CampaignEdge>>;
  nodes?: Maybe<Array<Campaign>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CampaignStats = {
  __typename?: "CampaignStats";
  totalTransmissions: Scalars["Float"]["output"];
};

/** drivers card types! */
export enum CardType {
  NationalIdentityCard = "NationalIdentityCard",
  Passport = "Passport"
}

export type CodeOtp = {
  __typename?: "CodeOtp";
  /** Account CodeOtp code! */
  code?: Maybe<Scalars["String"]["output"]>;
  /** Account CodeOtp expires! */
  expires?: Maybe<Scalars["DateTime"]["output"]>;
};

export type Contact = {
  __typename?: "Contact";
  /** Organization contact email! */
  email?: Maybe<Scalars["String"]["output"]>;
  /** Organization contact phone number! */
  phone?: Maybe<Scalars["String"]["output"]>;
};

/** driver emergency contact's relationship! */
export enum ContactRelationShip {
  Aunt = "Aunt",
  Brother = "Brother",
  Cousin = "Cousin",
  Father = "Father",
  GrandParent = "GrandParent",
  Mother = "Mother",
  Sister = "Sister",
  Uncle = "Uncle",
  Wife = "Wife"
}

export type CreateAgencyInput = {
  /** Agency address! */
  address: AddressInput;
  /** Agency email! */
  email: Scalars["String"]["input"];
  /** Agency name! */
  name: Scalars["String"]["input"];
  /** The organization to which the agency belongs */
  orgarnisationId: Scalars["ID"]["input"];
  /** Agency phone info! */
  phone: PhoneInput;
};

export type CreateCampaignInput = {
  /** The agency id to which the receiver belongs! */
  agencyId: Scalars["String"]["input"];
  /** Campaign name! */
  name: Scalars["String"]["input"];
  /** Campaign start date! */
  startDate: Scalars["DateTime"]["input"];
};

export type CreateDiscoveryUserInput = {
  email: Scalars["String"]["input"];
  firstname: Scalars["String"]["input"];
  lastname: Scalars["String"]["input"];
  organizationId: Scalars["ID"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  roleId: Scalars["ID"]["input"];
};

export type CreateDriverInput = {
  address?: InputMaybe<AddressInput>;
  avaibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  cardID?: InputMaybe<Scalars["String"]["input"]>;
  cardType?: InputMaybe<CardType>;
  criminalRecord?: InputMaybe<Scalars["String"]["input"]>;
  driverLicence?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  emergencyContactFirstname?: InputMaybe<Scalars["String"]["input"]>;
  emergencyContactLastname?: InputMaybe<Scalars["String"]["input"]>;
  emergencyContactPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  firstname: Scalars["String"]["input"];
  lastname: Scalars["String"]["input"];
  licenceType?: InputMaybe<Array<LicenceType>>;
  organization?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  proofAddress?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateModuleInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  platformId: Scalars["ID"]["input"];
};

/** Organization creation input! */
export type CreateOrganizationInput = {
  /** Short description of the organization! */
  about?: InputMaybe<Scalars["String"]["input"]>;
  /** Organization contact email! */
  contactEmail: Scalars["String"]["input"];
  /** Organization contact phone number! */
  contactPhone: Scalars["String"]["input"];
  /** Organization email! */
  email: Scalars["String"]["input"];
  /** Is Gks organisation to handle their fields! */
  isGKS?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Is isRestrictions organisation to handle restrictions on mobile app! */
  isRestrictions: Scalars["Boolean"]["input"];
  /** Organization logo path! */
  logo?: InputMaybe<Scalars["String"]["input"]>;
  /** Organization name! */
  name: Scalars["String"]["input"];
  /** Organization phone number! */
  phone: Scalars["String"]["input"];
  /** Cancellation reasons of the organization! */
  reasons?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Organization website link! */
  website?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreatePermissionInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  moduleId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type CreatePlatformInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CreateReceiverInput = {
  address: AddressInput;
  agencyId: Scalars["String"]["input"];
  district?: InputMaybe<Scalars["String"]["input"]>;
  extraField1?: InputMaybe<Scalars["String"]["input"]>;
  extraField2?: InputMaybe<Scalars["String"]["input"]>;
  firstname?: InputMaybe<Scalars["String"]["input"]>;
  fullname?: InputMaybe<Scalars["String"]["input"]>;
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  reference: Scalars["String"]["input"];
  specificationAddress?: InputMaybe<Scalars["String"]["input"]>;
  zone?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateRoleInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  permissions: Array<Scalars["ID"]["input"]>;
};

export type CreateTransmissionInput = {
  /** The campaign id to which the transmission belongs! */
  campaignId: Scalars["String"]["input"];
  /** Transmission start date! */
  endDate: Scalars["DateTime"]["input"];
  /** Transmission name! */
  name: Scalars["String"]["input"];
  /** The organization id to which the transmission belongs! */
  organizationId: Scalars["String"]["input"];
  /** Transmission start date! */
  startDate: Scalars["DateTime"]["input"];
};

export type CreateUserInput = {
  email: Scalars["String"]["input"];
  firstname: Scalars["String"]["input"];
  lastname: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  roleId: Scalars["ID"]["input"];
};

/** Dates filter types! */
export enum DateType {
  CreationDate = "CreationDate",
  DeliveryDate = "DeliveryDate"
}

export type DeliveriesByReceiverArgs = {
  receiverId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Delivery = {
  __typename?: "Delivery";
  _id?: Maybe<Scalars["ID"]["output"]>;
  createdAt: Scalars["String"]["output"];
  deliveryInfos?: Maybe<DeliveryInfos>;
  driver?: Maybe<Driver>;
  events?: Maybe<Array<DeliveryEvent>>;
  isCancelled: Scalars["Boolean"]["output"];
  receiver?: Maybe<Receiver>;
  status?: Maybe<Status>;
  transmission?: Maybe<Transmission>;
  updatedAt: Scalars["String"]["output"];
};

export type DeliveryActivtiesQueryArgs = {
  exportFilter?: InputMaybe<ExportReceiverInput>;
  filter?: InputMaybe<BaseQueryFilter>;
  paging?: InputMaybe<Page2Args>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeliveryEvent = {
  __typename?: "DeliveryEvent";
  cancellationReason?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["String"]["output"];
  event: Status;
  eventCreator: Scalars["String"]["output"];
  isDriverCreator: Scalars["Boolean"]["output"];
};

export type DeliveryInfos = {
  __typename?: "DeliveryInfos";
  hasBeenReceivedByOwner: Scalars["Boolean"]["output"];
  hasReceivedPreviousInvoice: Scalars["Boolean"]["output"];
  receiverInfos?: Maybe<DeliveryReceiver>;
};

export type DeliveryInfosInput = {
  hasBeenReceivedByOwner?: Scalars["Boolean"]["input"];
  hasReceivedPreviousInvoice: Scalars["Boolean"]["input"];
  receiverInfos?: InputMaybe<DeliveryReceiverInput>;
};

export type DeliveryReceiver = {
  __typename?: "DeliveryReceiver";
  fullname?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type DeliveryReceiverInput = {
  fullname: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type DiscoveryUser = {
  __typename?: "DiscoveryUser";
  _id?: Maybe<Scalars["ID"]["output"]>;
  account: Account;
  createdAt: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  firstname: Scalars["String"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  lastname: Scalars["String"]["output"];
  organization?: Maybe<Organization>;
  organizationId: Scalars["ID"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  role: Role;
  updatedAt: Scalars["String"]["output"];
};

export type Driver = {
  __typename?: "Driver";
  _id?: Maybe<Scalars["ID"]["output"]>;
  /** driver address! */
  address?: Maybe<Address>;
  /** driver availability! */
  avaibility?: Maybe<Scalars["Boolean"]["output"]>;
  /** is driver blocked */
  blocked?: Maybe<Scalars["Boolean"]["output"]>;
  /** driver card id! */
  cardID?: Maybe<Scalars["String"]["output"]>;
  cardType?: Maybe<CardType>;
  contactRelationShip?: Maybe<ContactRelationShip>;
  /** driver contract end! */
  contractEnd?: Maybe<Scalars["String"]["output"]>;
  /** driver contract start! */
  contractStart?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["String"]["output"];
  /** driver criminal record! */
  criminalRecord?: Maybe<Scalars["String"]["output"]>;
  /** driver licence! */
  driverLicence?: Maybe<Scalars["String"]["output"]>;
  /** driver email address! */
  email?: Maybe<Scalars["String"]["output"]>;
  /** driver emergency contact's first name! */
  emergencyContactFirstname?: Maybe<Scalars["String"]["output"]>;
  /** driver emergency contact's last name! */
  emergencyContactLastname?: Maybe<Scalars["String"]["output"]>;
  /** driver emergency driver contact's phone number! */
  emergencyContactPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  /** driver first name! */
  firstname?: Maybe<Scalars["String"]["output"]>;
  /** is driver trained! */
  isDriverTrained?: Maybe<Scalars["Boolean"]["output"]>;
  /** driver last name! */
  lastname?: Maybe<Scalars["String"]["output"]>;
  licenceType?: Maybe<LicenceType>;
  organization?: Maybe<Scalars["String"]["output"]>;
  /** is driver paid by paps! */
  paidByPaps?: Maybe<Scalars["Boolean"]["output"]>;
  /** the partner where the driver belong! */
  partner?: Maybe<Partner>;
  /** driver paiement method! */
  paymentMode?: Maybe<Scalars["String"]["output"]>;
  /** driver phone number! */
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  /** driver profile picture! */
  profilePicture?: Maybe<Scalars["String"]["output"]>;
  /** driver proof of address! */
  proofAddress?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
  /** driver username! */
  username?: Maybe<Scalars["String"]["output"]>;
};

export type DriverEdge = {
  __typename?: "DriverEdge";
  cursor: Scalars["String"]["output"];
  node: Driver;
};

export type DriverForPaps = {
  __typename?: "DriverForPaps";
  _id: Scalars["ID"]["output"];
  /** is driver blocked */
  blocked?: Maybe<Scalars["Boolean"]["output"]>;
  /** driver email address! */
  email?: Maybe<Scalars["String"]["output"]>;
  /** driver first name! */
  firstname?: Maybe<Scalars["String"]["output"]>;
  /** is driver deleted! */
  isDeleted?: Maybe<Scalars["Boolean"]["output"]>;
  /** driver last name! */
  lastname?: Maybe<Scalars["String"]["output"]>;
  /** the partner where the driver belong! */
  partner?: Maybe<Partner>;
  /** driver phone number! */
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  /** Papser platform */
  platform: Array<EPapserPlatform>;
  /** driver UID! */
  uid: Scalars["String"]["output"];
};

export type DriverPage = {
  __typename?: "DriverPage";
  edges?: Maybe<Array<DriverEdge>>;
  nodes?: Maybe<Array<Driver>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum EPapserPlatform {
  Discovery = "DISCOVERY",
  Ops = "OPS"
}

export type ExportReceiverInput = {
  /** Receiver address! */
  address?: InputMaybe<Scalars["String"]["input"]>;
  dateType?: InputMaybe<DateType>;
  /** Delivery driver! */
  driver?: InputMaybe<Scalars["String"]["input"]>;
  /** Date from! */
  fromDate?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<PlatformType>;
  status?: InputMaybe<Status>;
  /** Date to! */
  toDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetDriversByIdsInput = {
  /** Driver id list */
  driverIds: Array<Scalars["ID"]["input"]>;
};

export type GroupedDeliveryActivities = {
  __typename?: "GroupedDeliveryActivities";
  date: Scalars["String"]["output"];
  driver: DriverForPaps;
  totalDeliveries: Scalars["Float"]["output"];
  transmission: Transmission;
};

/** drivers licence types! */
export enum LicenceType {
  BigLoad = "BigLoad",
  Car = "Car",
  Motorcycle = "Motorcycle"
}

export type Location = {
  __typename?: "Location";
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
};

export type LocationInput = {
  /** Google latitude! */
  latitude: Scalars["String"]["input"];
  /** Google longitude! */
  longitude: Scalars["String"]["input"];
};

export type Module = {
  __typename?: "Module";
  _id?: Maybe<Scalars["ID"]["output"]>;
  createdAt: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  isDeleted: Scalars["Boolean"]["output"];
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  permissions?: Maybe<Array<Permission>>;
  platform?: Maybe<Platform>;
  updatedAt: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  addReceiversToTransmission: Transmission;
  assignDriversToTransmission: Transmission;
  auhtenticateOtp: AuthenticateCodeResponse;
  auth: AuthResponse;
  authDiscovery: AuthResponse;
  cancelDelivery: Delivery;
  closeCampaign: Campaign;
  createAgency: Agency;
  createCampaign: Campaign;
  createDiscoveryDriver: Driver;
  createDiscoveryUser: DiscoveryUser;
  createDriver: DriverForPaps;
  createModule: Module;
  createOrganization: Organization;
  createPermission: Permission;
  createPlatform: Platform;
  createReceiver: Receiver;
  createReceiverByPapser: Receiver;
  createRole: Role;
  createTransmission: Transmission;
  createUser: User;
  deleteAgency: Agency;
  deleteModule: Module;
  deleteOrganization: Organization;
  deletePermission: Permission;
  deletePlaform: Platform;
  deleteReceiver: Receiver;
  deleteRole: Role;
  deleteTransmission: Transmission;
  deleteUser: User;
  driversById: Array<DriverForPaps>;
  driversByIdForPaps: Array<DriverForPaps>;
  proceedDelivery: Delivery;
  removePapsersFromTransmission: Transmission;
  resendActivationEmail: Scalars["String"]["output"];
  resetPassword: Scalars["String"]["output"];
  resetPasswordRequest: Scalars["String"]["output"];
  sendCode: Scalars["String"]["output"];
  transferReceivers: Transmission;
  updateAgency: Agency;
  updateCampaign: Campaign;
  updateDeliveryStatus: Delivery;
  updateModule: Module;
  updateOrganization: Organization;
  updatePassword: Scalars["String"]["output"];
  updatePermission: Permission;
  updatePlatform: Platform;
  updateProfile: User;
  updateReceiver: Receiver;
  updateReceiverLocationPcitures: Receiver;
  updateRole: Role;
  updateRolePermissions: Role;
  updateTransmission: Transmission;
  updateUser: User;
  verifyAccount: AuthResponse;
};

export type MutationAddReceiversToTransmissionArgs = {
  input: AddReceiversToTransmissionInput;
};

export type MutationAssignDriversToTransmissionArgs = {
  input: AssignDriversToTransmissionInput;
};

export type MutationAuhtenticateOtpArgs = {
  input: AuthenticateCodeInput;
};

export type MutationAuthArgs = {
  input: AuthInput;
};

export type MutationAuthDiscoveryArgs = {
  input: AuthInput;
};

export type MutationCancelDeliveryArgs = {
  input: ProceedDeliveryInput;
};

export type MutationCloseCampaignArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateAgencyArgs = {
  input: CreateAgencyInput;
};

export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};

export type MutationCreateDiscoveryDriverArgs = {
  input: CreateDriverInput;
};

export type MutationCreateDiscoveryUserArgs = {
  input: CreateDiscoveryUserInput;
};

export type MutationCreateDriverArgs = {
  input: CreateDriverInput;
};

export type MutationCreateModuleArgs = {
  input: CreateModuleInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};

export type MutationCreatePlatformArgs = {
  input: CreatePlatformInput;
};

export type MutationCreateReceiverArgs = {
  input: CreateReceiverInput;
};

export type MutationCreateReceiverByPapserArgs = {
  input: CreateReceiverByPapserInput;
};

export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationCreateTransmissionArgs = {
  input: CreateTransmissionInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteAgencyArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteModuleArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePermissionArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeletePlaformArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteReceiverArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteRoleArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteTransmissionArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDriversByIdArgs = {
  input: GetDriversByIdsInput;
};

export type MutationDriversByIdForPapsArgs = {
  input: GetDriversByIdsInput;
};

export type MutationProceedDeliveryArgs = {
  input: ProceedDeliveryInput;
};

export type MutationRemovePapsersFromTransmissionArgs = {
  driverType: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  papsers: Array<Scalars["String"]["input"]>;
};

export type MutationResendActivationEmailArgs = {
  input: ResendVerificationEmailInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationResetPasswordRequestArgs = {
  input: ResetPasswordRequestInput;
};

export type MutationSendCodeArgs = {
  input: SendCodeInput;
};

export type MutationTransferReceiversArgs = {
  fromTransmissionId: Scalars["ID"]["input"];
  receiversIds: Array<Scalars["String"]["input"]>;
  toTransmissionId: Scalars["ID"]["input"];
};

export type MutationUpdateAgencyArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateAgencyInput;
};

export type MutationUpdateCampaignArgs = {
  id: Scalars["ID"]["input"];
  input: CreateCampaignInput;
};

export type MutationUpdateDeliveryStatusArgs = {
  input: UpdateDeliveryStatusInput;
};

export type MutationUpdateModuleArgs = {
  input: UpdateModuleInput;
};

export type MutationUpdateOrganizationArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateOrganizationInput;
};

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};

export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};

export type MutationUpdatePlatformArgs = {
  input: UpdatePlatformInput;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationUpdateReceiverArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateReceiverInput;
};

export type MutationUpdateReceiverLocationPcituresArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateReceiverLocationPicturesInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationUpdateRolePermissionsArgs = {
  input: UpdateRolePermissionsInput;
};

export type MutationUpdateTransmissionArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateTransmissionInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationVerifyAccountArgs = {
  input: AccountVerificationInput;
};

export type Organization = {
  __typename?: "Organization";
  _id?: Maybe<Scalars["ID"]["output"]>;
  /** Short description of the organization! */
  about?: Maybe<Scalars["String"]["output"]>;
  agencies?: Maybe<Array<Agency>>;
  /** Organization info! */
  contact?: Maybe<Contact>;
  createdAt: Scalars["String"]["output"];
  /** Organization email! */
  email?: Maybe<Scalars["String"]["output"]>;
  /** Is Gks organisation to handle their fields! */
  isGKS?: Maybe<Scalars["Boolean"]["output"]>;
  /** Is isRestrictions organisation to handle restrictions on mobile app! */
  isRestrictions?: Maybe<Scalars["Boolean"]["output"]>;
  /** Organization logo path! */
  logo?: Maybe<Scalars["String"]["output"]>;
  /** Organization name! */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Organization phone number! */
  phone?: Maybe<Scalars["String"]["output"]>;
  /** Cancellation reasons of the organization! */
  reasons?: Maybe<Array<Scalars["String"]["output"]>>;
  receivers?: Maybe<Array<Receiver>>;
  stats: OrganizationStats;
  updatedAt: Scalars["String"]["output"];
  users?: Maybe<Array<DiscoveryUser>>;
  /** Organization website link! */
  website?: Maybe<Scalars["String"]["output"]>;
};

export type OrganizationEdge = {
  __typename?: "OrganizationEdge";
  cursor: Scalars["String"]["output"];
  node: Organization;
};

export type OrganizationPage = {
  __typename?: "OrganizationPage";
  edges?: Maybe<Array<OrganizationEdge>>;
  nodes?: Maybe<Array<Organization>>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrganizationStats = {
  __typename?: "OrganizationStats";
  totalAgencies: Scalars["Float"]["output"];
  totalReceivers: Scalars["Float"]["output"];
};

export type Page2Args = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  countAfter: Scalars["Int"]["output"];
  countBefore: Scalars["Int"]["output"];
  countCurrent: Scalars["Int"]["output"];
  countTotal: Scalars["Int"]["output"];
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PapserCountDeliveries = {
  __typename?: "PapserCountDeliveries";
  totalDelivered: Scalars["Int"]["output"];
  totalNotDelivered: Scalars["Int"]["output"];
};

export type Partner = {
  __typename?: "Partner";
  _id?: Maybe<Scalars["ID"]["output"]>;
  partner_UID?: Maybe<Scalars["String"]["output"]>;
  partner_address?: Maybe<Scalars["String"]["output"]>;
  partner_company_name?: Maybe<Scalars["String"]["output"]>;
  partner_contact_email?: Maybe<Scalars["String"]["output"]>;
  partner_contact_firstname?: Maybe<Scalars["String"]["output"]>;
  partner_contact_lastname?: Maybe<Scalars["String"]["output"]>;
  partner_contact_phone_number?: Maybe<Scalars["String"]["output"]>;
  partner_contract_end_date?: Maybe<Scalars["String"]["output"]>;
  partner_contract_is_signed?: Maybe<Scalars["Boolean"]["output"]>;
  partner_contract_start_date?: Maybe<Scalars["String"]["output"]>;
  partner_created_at?: Maybe<Scalars["String"]["output"]>;
  partner_email?: Maybe<Scalars["String"]["output"]>;
  partner_ninea_number?: Maybe<Scalars["String"]["output"]>;
  partner_ninea_url?: Maybe<Scalars["String"]["output"]>;
  partner_phone_number?: Maybe<Scalars["String"]["output"]>;
  partner_trade_register?: Maybe<Scalars["String"]["output"]>;
};

export type Permission = {
  __typename?: "Permission";
  _id?: Maybe<Scalars["ID"]["output"]>;
  createdAt: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  isDeleted: Scalars["Boolean"]["output"];
  key?: Maybe<Scalars["String"]["output"]>;
  module: Module;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
};

export type Phone = {
  __typename?: "Phone";
  /** Fixed-line number! */
  fix?: Maybe<Scalars["String"]["output"]>;
  /** Mobile number! */
  mobile?: Maybe<Scalars["String"]["output"]>;
};

export type PhoneInput = {
  /** Fixed-line number! */
  fix: Scalars["String"]["input"];
  /** Mobile number! */
  mobile: Scalars["String"]["input"];
};

export type Platform = {
  __typename?: "Platform";
  _id?: Maybe<Scalars["ID"]["output"]>;
  createdAt: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  isDeleted: Scalars["Boolean"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  modules?: Maybe<Array<Module>>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
};

export type ProceedDeliveryInput = {
  deliveryInfos: DeliveryInfosInput;
  driver: Scalars["ID"]["input"];
  receiver: Scalars["ID"]["input"];
  transmission: Scalars["ID"]["input"];
};

export type Query = {
  __typename?: "Query";
  agencies: AgencyPage;
  agency: Agency;
  campaign: Campaign;
  campaigns: CampaignPage;
  deliveriesByReceiver: Array<Delivery>;
  discoveryDriver: Driver;
  discoveryUser: DiscoveryUser;
  discoveryUsers: Array<DiscoveryUser>;
  drivers: DriverPage;
  driversForPaps: Array<DriverForPaps>;
  exportReceiversByTransmissionOrCampaign: Array<Receiver>;
  groupedDeliveryActivities: Array<GroupedDeliveryActivities>;
  module: Module;
  modules: Array<Module>;
  organization: Organization;
  organizations: OrganizationPage;
  permission: Permission;
  permissions: Array<Permission>;
  platform: Platform;
  platforms: Array<Platform>;
  receiver: Receiver;
  receivers?: Maybe<ReceiverPage>;
  receiversByTransmission: ReceiverPage;
  receiversNotInTransmission: Array<Receiver>;
  role: Role;
  roles: Array<Role>;
  totalDeliveriesByTransmissionAndDriver: PapserCountDeliveries;
  transmission: Transmission;
  transmissions: TransmissionPage;
  transmissionsByDriver: Array<Transmission>;
  user: User;
  users: Array<User>;
  viewer: User;
};

export type QueryAgenciesArgs = {
  args?: InputMaybe<BaseQueryArgs>;
};

export type QueryAgencyArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCampaignArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCampaignsArgs = {
  args?: InputMaybe<BaseQueryArgs>;
};

export type QueryDeliveriesByReceiverArgs = {
  args?: InputMaybe<DeliveriesByReceiverArgs>;
};

export type QueryDiscoveryDriverArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDiscoveryUserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDriversArgs = {
  args?: InputMaybe<BaseQueryArgs>;
};

export type QueryExportReceiversByTransmissionOrCampaignArgs = {
  campaignId?: InputMaybe<Scalars["ID"]["input"]>;
  input: ExportReceiverInput;
  transmissionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGroupedDeliveryActivitiesArgs = {
  args?: InputMaybe<DeliveryActivtiesQueryArgs>;
};

export type QueryModuleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrganizationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPermissionArgs = {
  id: Scalars["String"]["input"];
};

export type QueryPlatformArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryReceiverArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryReceiversArgs = {
  args?: InputMaybe<BaseQueryArgs>;
};

export type QueryReceiversByTransmissionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ExportReceiverInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  searchText?: InputMaybe<Scalars["String"]["input"]>;
  transmissionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryReceiversNotInTransmissionArgs = {
  transmissionId: Scalars["ID"]["input"];
};

export type QueryRoleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTotalDeliveriesByTransmissionAndDriverArgs = {
  driver: Scalars["ID"]["input"];
  transmission: Scalars["ID"]["input"];
};

export type QueryTransmissionArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTransmissionsArgs = {
  args?: InputMaybe<BaseQueryArgs>;
};

export type QueryTransmissionsByDriverArgs = {
  driverId: Scalars["ID"]["input"];
};

export type QueryUserArgs = {
  id: Scalars["ID"]["input"];
};

export type Receiver = {
  __typename?: "Receiver";
  _id?: Maybe<Scalars["ID"]["output"]>;
  /** Receiver google address! */
  address?: Maybe<Address>;
  /** The agency to which the receiver belongs! */
  agency?: Maybe<Agency>;
  createdAt: Scalars["String"]["output"];
  /** Receiver creation date! */
  creationDate?: Maybe<Scalars["String"]["output"]>;
  deliveries: Array<Delivery>;
  /** Receiver delivery date! */
  deliveryDate?: Maybe<Scalars["String"]["output"]>;
  /** Receiver delivery hour! */
  deliveryHour?: Maybe<Scalars["String"]["output"]>;
  /** Delivery receiver Info! */
  deliveryReceiverInfo?: Maybe<DeliveryReceiver>;
  didReceiveCurrentTransmission?: Maybe<Scalars["Boolean"]["output"]>;
  /** Receiver district address! */
  district?: Maybe<Scalars["String"]["output"]>;
  /** Driver who made delivery! */
  driver?: Maybe<Scalars["String"]["output"]>;
  /** Receiver email! */
  email?: Maybe<Scalars["String"]["output"]>;
  /** Receiver extra field 1! */
  extraField1?: Maybe<Scalars["String"]["output"]>;
  /** Receiver extra field 2! */
  extraField2?: Maybe<Scalars["String"]["output"]>;
  /** Receiver firsname! */
  firstname?: Maybe<Scalars["String"]["output"]>;
  /** Receiver fullname! */
  fullname?: Maybe<Scalars["String"]["output"]>;
  lastDelivery?: Maybe<Delivery>;
  lastDeliveryStatus?: Maybe<Status>;
  lastReceptionDate?: Maybe<Scalars["String"]["output"]>;
  /** Receiver lastname! */
  lastname?: Maybe<Scalars["String"]["output"]>;
  /** Picture(s) of the receiver location */
  locationPictures?: Maybe<Array<Scalars["String"]["output"]>>;
  /** The organization to which the receiver belongs */
  organization?: Maybe<Organization>;
  /** Papser who create receiver! */
  papserId?: Maybe<Scalars["String"]["output"]>;
  /** Receiver phone number! */
  phone?: Maybe<Scalars["String"]["output"]>;
  /** Receiver external reference PDI! */
  refPDI?: Maybe<Scalars["String"]["output"]>;
  /** Receiver external reference to recall! */
  refRappeler?: Maybe<Scalars["String"]["output"]>;
  /** Receiver external reference contrat! */
  reference?: Maybe<Scalars["String"]["output"]>;
  /** Receiver specification address! */
  specificationAddress?: Maybe<Scalars["String"]["output"]>;
  transmission?: Maybe<Array<Maybe<Transmission>>>;
  updatedAt: Scalars["String"]["output"];
  /** Receiver zone address! */
  zone?: Maybe<Scalars["String"]["output"]>;
};

export type ReceiverEdge = {
  __typename?: "ReceiverEdge";
  cursor: Scalars["String"]["output"];
  node: Receiver;
};

export type ReceiverPage = {
  __typename?: "ReceiverPage";
  edges?: Maybe<Array<ReceiverEdge>>;
  nodes?: Maybe<Array<Receiver>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ResendVerificationEmailInput = {
  source?: InputMaybe<AccountSource>;
  userId: Scalars["String"]["input"];
};

export type ResetPasswordInput = {
  password: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type ResetPasswordRequestInput = {
  email: Scalars["String"]["input"];
};

export type Role = {
  __typename?: "Role";
  _id?: Maybe<Scalars["ID"]["output"]>;
  createdAt: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  isDeleted: Scalars["Boolean"]["output"];
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  permissions?: Maybe<Array<Permission>>;
  updatedAt: Scalars["String"]["output"];
  users: Array<User>;
};

export type SendCodeInput = {
  phoneNumber: Scalars["String"]["input"];
};

export type Transmission = {
  __typename?: "Transmission";
  _id?: Maybe<Scalars["ID"]["output"]>;
  /** The agency to which the transmission belongs */
  agency?: Maybe<Agency>;
  /** The campaign to which the transmission belongs */
  campaign?: Maybe<Campaign>;
  createdAt: Scalars["String"]["output"];
  /** Drivers assigned to the transmission */
  discoveryDrivers?: Maybe<Array<Driver>>;
  /** Drivers assigned to the transmission */
  drivers?: Maybe<Array<DriverForPaps>>;
  /** Transmission end date! */
  endDate?: Maybe<Scalars["String"]["output"]>;
  /** Is transmission closed! */
  isClose?: Maybe<Scalars["Boolean"]["output"]>;
  /** Transmission name! */
  name: Scalars["String"]["output"];
  /** The organization to which the transmission belongs */
  organization?: Maybe<Organization>;
  /** The transmission receivers */
  receivers?: Maybe<Array<Receiver>>;
  /** Transmission start date! */
  startDate?: Maybe<Scalars["String"]["output"]>;
  state: TransmissionState;
  stats: TransmissionStats;
  updatedAt: Scalars["String"]["output"];
};

export type TransmissionEdge = {
  __typename?: "TransmissionEdge";
  cursor: Scalars["String"]["output"];
  node: Transmission;
};

export type TransmissionPage = {
  __typename?: "TransmissionPage";
  edges?: Maybe<Array<TransmissionEdge>>;
  nodes?: Maybe<Array<Transmission>>;
  pageInfo?: Maybe<PageInfo>;
};

/** state of the transmission! */
export enum TransmissionState {
  Risk = "Risk",
  Stable = "Stable",
  Trouble = "Trouble"
}

export type TransmissionStats = {
  __typename?: "TransmissionStats";
  totalAssignedDrivers: Scalars["Float"]["output"];
  totalCancelledDeliveries?: Maybe<Scalars["Float"]["output"]>;
  totalDeliveries?: Maybe<Scalars["Float"]["output"]>;
  totalNotDelivered?: Maybe<Scalars["Float"]["output"]>;
  totalReceivers: Scalars["Float"]["output"];
  totalSuccessful?: Maybe<Scalars["Float"]["output"]>;
};

export type UpdateAgencyInput = {
  /** Agency address! */
  address?: InputMaybe<AddressInput>;
  /** Agency email! */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /** Agency name! */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Agency phone info! */
  phone?: InputMaybe<PhoneInput>;
};

export type UpdateDeliveryStatusInput = {
  cancellationReason?: Scalars["String"]["input"];
  isDriverCreator?: Scalars["Boolean"]["input"];
  receiver: Scalars["ID"]["input"];
  status: Status;
  transmission: Scalars["ID"]["input"];
  user: Scalars["ID"]["input"];
};

export type UpdateModuleInput = {
  _id: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Organization updating input! */
export type UpdateOrganizationInput = {
  /** Short description of the organization! */
  about?: InputMaybe<Scalars["String"]["input"]>;
  /** Organization contact email! */
  contactEmail?: InputMaybe<Scalars["String"]["input"]>;
  /** Organization contact phone number! */
  contactPhone?: InputMaybe<Scalars["String"]["input"]>;
  /** Organization email! */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /** Is Gks organisation to handle their fields! */
  isGKS?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Is isRestrictions organisation to handle restrictions on mobile app! */
  isRestrictions?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Organization logo path! */
  logo?: InputMaybe<Scalars["String"]["input"]>;
  /** Organization name! */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Organization phone number! */
  phone?: InputMaybe<Scalars["String"]["input"]>;
  /** Cancellation reasons of the organization! */
  reasons?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Organization website link! */
  website?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePasswordInput = {
  newPassword: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type UpdatePermissionInput = {
  _id: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePlatformInput = {
  _id: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateProfileInput = {
  firstname?: InputMaybe<Scalars["String"]["input"]>;
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateReceiverInput = {
  address?: InputMaybe<AddressInput>;
  agencyId?: InputMaybe<Scalars["String"]["input"]>;
  district?: InputMaybe<Scalars["String"]["input"]>;
  extraField1?: InputMaybe<Scalars["String"]["input"]>;
  extraField2?: InputMaybe<Scalars["String"]["input"]>;
  firstname?: InputMaybe<Scalars["String"]["input"]>;
  fullname?: InputMaybe<Scalars["String"]["input"]>;
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  specificationAddress?: InputMaybe<Scalars["String"]["input"]>;
  zone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateReceiverLocationPicturesInput = {
  pictures: Array<Scalars["String"]["input"]>;
};

export type UpdateRoleInput = {
  _id: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateRolePermissionsInput = {
  _id: Scalars["ID"]["input"];
  permissions: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type UpdateTransmissionInput = {
  /** The campaign id to which the transmission belongs! */
  campaignId?: InputMaybe<Scalars["String"]["input"]>;
  /** Transmission start date! */
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Transmission name! */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** The organization id to which the transmission belongs! */
  organizationId?: InputMaybe<Scalars["String"]["input"]>;
  /** Transmission start date! */
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UpdateUserInput = {
  _id: Scalars["ID"]["input"];
  firstname: Scalars["String"]["input"];
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  roleId: Scalars["ID"]["input"];
};

export type User = {
  __typename?: "User";
  _id?: Maybe<Scalars["ID"]["output"]>;
  account?: Maybe<Account>;
  createdAt: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  firstname: Scalars["String"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  lastname: Scalars["String"]["output"];
  organizationId?: Maybe<Scalars["ID"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  role: Role;
  updatedAt: Scalars["String"]["output"];
};

export type UserDriver = {
  __typename?: "UserDriver";
  _id?: Maybe<Scalars["ID"]["output"]>;
  createdAt: Scalars["String"]["output"];
  /** The agency to which the receiver belongs! */
  driver?: Maybe<Driver>;
  /** Account ExpoToken! */
  expoToken?: Maybe<Scalars["String"]["output"]>;
  /** Account CodeOtp! */
  otp?: Maybe<CodeOtp>;
  /** Account phone number! */
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
  /** Account Verified! */
  verified?: Maybe<Scalars["String"]["output"]>;
};

export type CreateReceiverByPapserInput = {
  address: AddressInput;
  district?: InputMaybe<Scalars["String"]["input"]>;
  extraField1?: InputMaybe<Scalars["String"]["input"]>;
  extraField2?: InputMaybe<Scalars["String"]["input"]>;
  firstname?: InputMaybe<Scalars["String"]["input"]>;
  fullname?: InputMaybe<Scalars["String"]["input"]>;
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  papserId: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  reference: Scalars["String"]["input"];
  specificationAddress?: InputMaybe<Scalars["String"]["input"]>;
  transmissionId: Scalars["String"]["input"];
  zone?: InputMaybe<Scalars["String"]["input"]>;
};

/** platform filter types! */
export enum PlatformType {
  Mobile = "Mobile",
  Web = "Web"
}

/** Status of the Delivery! */
export enum Status {
  Cancelled = "CANCELLED",
  Delivered = "DELIVERED",
  NotDelivered = "NOT_DELIVERED",
  Successful = "SUCCESSFUL",
  ToProcess = "TO_PROCESS"
}
