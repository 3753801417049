import { PagingType } from "app/types";

export const DRAWER_WITH = 240;
export const APP_BAR_HEIGHT = 64;
export const LOGO_PATH = "/logo.png";
export const ROW_PER_PAGE_OPTIONS = [10, 20, 50];
export const TABLE_HEIGHT = "calc(100vh - 450px)";
export const DEFAULT_PAGING: PagingType = {
  pagingType: "forward",
  rows: ROW_PER_PAGE_OPTIONS[0]
};
export const GOOGLE_MAPS_API_KEY = "AIzaSyDCR2ycw5W4neIDEI7h3xnBtoDBk92h394";

export const IMPORT_ROWS_LIMIT = 30000;

export const DEFAULT_RECEIVERS_FILTER = {
  status: null,
  driver: null
};

export const ORGANIZATIONS = [
  {
    id: "xxx",
    name: "SENEAU"
  },
  {
    id: "6455e37d325948001c5bb559",
    name: "GKS"
  }
];

export const GKS_ORGANIZATIONS = ["6455e37d325948001c5bb559", "65f585880861dd0002908192"];

export const COLUMNS_MATCH = {
  reference: "reference",
  fullname: "fullname",
  address: "address",
  phone_number: "phone_number",
  extra_field_1: "extra_field_1",
  extra_field_2: "extra_field_2"
};

export const GKS_COLUMNS_MATCH = {
  reference: "reference",
  phone_number: "phone_number",
  fullname: "fullname",
  district: "district",
  address: "address",
  zone: "zone"
};

export const GKS_HEADERS = [
  {
    key: "reference",
    label: "Identifiant unique du client",
    eg: "(reference)"
  },
  {
    key: "fullname",
    label: "Prénom & Nom",
    eg: "(fullname)"
  },
  {
    key: "phone_number",
    label: "Numéro de téléphone",
    eg: "(phone_number)"
  },

  {
    key: "address",
    label: "Adresse",
    eg: "(address)"
  },
  {
    key: "district",
    label: "Quartier client",
    eg: "(district)"
  },
  {
    key: "zone",
    label: "Zone de l'adresse du client",
    eg: "(zone)"
  }
];

export const HEADERS = [
  {
    key: "reference",
    label: "Identifiant unique du client (Reference)",
    eg: "(reference)"
  },
  {
    key: "fullname",
    label: "Prénom & Nom",
    eg: "(fullname)"
  },
  {
    key: "phone_number",
    label: "Numéro de téléphone",
    eg: "(phone_number)"
  },

  {
    key: "address",
    label: "Adresse",
    eg: "(address)"
  },
  {
    key: "extra_field_1",
    label: "Champ supplémentaire 1",
    eg: "(extra_field_1)"
  },
  {
    key: "extra_field_2",
    label: "Champ supplémentaire 2",
    eg: "(extra_field_2)"
  }
];

// eslint-disable-next-line no-shadow
export enum ListFilterType {
  Campaign = "CAMPAIGN",
  Transmission = "TRANSMISSION",
  Receiver = "RECEIVER"
}

// eslint-disable-next-line no-shadow
export enum PapsersType {
  OPS = "OPS",
  DISCOVERY = "DISCOVERY"
}
