import { gql } from "@apollo/client";

export const ALL_USERS = gql`
  query users {
    users {
      _id
      firstname
      lastname
      phone
      email
      role {
        _id
        name
      }
      account {
        isVerified
        enabled
      }
    }
  }
`;

export const DISCOVERY_USERS = gql`
  query discoveryUsers {
    discoveryUsers {
      _id
      firstname
      lastname
      phone
      email
      role {
        _id
        name
      }
      account {
        isVerified
        enabled
      }
    }
  }
`;

export const USER_BY_ID = gql`
  query user($id: ID!) {
    user(id: $id) {
      _id
      firstname
      lastname
      phone
      email
      role {
        _id
        name
        permissions {
          _id
          name
          module {
            _id
            name
          }
        }
      }
    }
  }
`;

export const DISCOVERY_USER_BY_ID = gql`
  query discoveryUser($id: ID!) {
    discoveryUser(id: $id) {
      _id
      firstname
      lastname
      phone
      email
      role {
        _id
        name
        permissions {
          _id
          name
          module {
            _id
            name
          }
        }
      }
    }
  }
`;

export const USER_PROFIL = gql`
  query {
    viewer {
      _id
      firstname
      lastname
      phone
      email
    }
  }
`;
